"use client";

import dynamic from "next/dynamic";

import React, { useContext, useEffect, useState } from "react";

const WorksSection = dynamic(() => import("./HowItWorks"), {ssr: false});
const AboutSection = dynamic(() => import("@components/ui/Sections/AboutSection"), {ssr: false});
const NewJobsSection = dynamic(() => import("./NewJobsSection"), {ssr: false});
const JobsBannerSection = dynamic(() => import("./JobsBanner"), {ssr: false});
const FAQsSection = dynamic(() => import("@components/ui/Sections/FAQsSection"), {ssr: false});

export default function HomeLazySections({
  translations,
  isArabic,
}: {
  translations: any;
  isArabic: boolean;
}) {
  const [shouldLoadSecondLevel, setShouldLoadSecondLevel] = useState(false);

  useEffect(() => {
    function handleScroll() {
      if (!shouldLoadSecondLevel && window.scrollY > 200) {
        // Load second-level components when user scrolls past 200 pixels
        setShouldLoadSecondLevel(true);
      }
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [shouldLoadSecondLevel]);

  return (
    <>
      {shouldLoadSecondLevel && (
        <>
          <WorksSection translations={translations} isArabic={isArabic} />
          <AboutSection variant="dark" className="bg-[#083b47]" translations={translations} isArabic={isArabic} />
          <NewJobsSection translations={translations} isArabic={isArabic} />
          <JobsBannerSection translations={translations} isArabic={isArabic} />
          <FAQsSection translations={translations} isArabic={isArabic} />
        </>
      )}
    </>
  );
}
