'use client'
import { useRouter } from "next/navigation";
import React from "react";
import { HiArrowLongLeft, HiArrowLongRight } from "react-icons/hi2";

export default function CompaniesLinkArrow({isArabic}: {isArabic: boolean}) {
  const router = useRouter()

  const navigateToCompanies = () => {
    router.push('/companies');
  };

  return (
    <button
      onClick={() => navigateToCompanies()}
      data-aos={isArabic ? "fade-left" : "fade-right"}
      data-aos-duration="400"
      data-aos-delay={4 + "00"}
      aria-label="View Companies Offering Employment"
      className="bg-white flex justify-center items-center rounded-full p-2 text-primary hover:opacity-80 transition text-5xl"
      type="button"
    >
      <span className="p-2 border rounded-full border-gray-300">
        {isArabic ? <HiArrowLongLeft /> : <HiArrowLongRight />}
      </span>
    </button>
  );
}
