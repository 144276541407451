import { useEffect, useRef } from "react";

const useClickOutside = (
  ref: React.RefObject<HTMLElement>,
  handler: () => void,
  excludeList: React.RefObject<HTMLElement>[] = []
) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        ref.current &&
        !ref.current.contains(event.target as Node) &&
        !excludeList.some(
          (elRef) =>
            elRef.current && elRef.current.contains(event.target as Node)
        )
      ) {
        handler();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, handler, excludeList]);
};

export default useClickOutside;
