"use client";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useRouter, useSearchParams } from "next/navigation";
import _debounce from "lodash/debounce";
import { useIntl } from "react-intl";
import Link from "next/link";

import useClickOutside from "@hooks/useClickOutside";
import { getSearchedFunctionalAreas } from "@queries/functionalAreaQueries";
import Search from "../search";

const homeJobSearch = () => {
  const searchParams = useSearchParams();
  const [searchTerm, setSearchTerm] = useState<string>(
    searchParams?.get("search") || ""
  );
  const [searchResults, setSearchResults] = useState<any[]>([]); // fix any
  const [loading, setLoading] = useState<boolean>(false);
  const [showResults, setShowResults] = useState<boolean>(false);
  const router = useRouter();

  const intl = useIntl();
  const homeTranslation: any = intl.messages.common;

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setShowResults(true);
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    const debouncedSearch = _debounce(async (query = ""): Promise<void> => {
      if (!query || !showResults) return setSearchResults([]);

      try {
        setLoading(true);
        const data = await getSearchedFunctionalAreas(query);
        setSearchResults(data || []);
      } catch (error) {
        console.error("Error searching for jobs:", error);
      } finally {
        setLoading(false);
      }
    }, 500);

    debouncedSearch(searchTerm);

    return () => {
      // Cleanup debounce on unmount
      debouncedSearch.cancel();
    };
  }, [searchTerm, showResults]);

  const searchSectionRef = useRef<HTMLDivElement>(null);

  useClickOutside(searchSectionRef, () => {
    setShowResults(false);
  });

  const loadingResultsCount = new Array(5).fill(null);

  const handleSearchJobs = (term: string) => {
    setShowResults(false);
    setSearchTerm(term);
    router.push(
      `/jobs?page=1${term ? `&search=${term}` : ""}${
        searchParams?.get("sort_by")
          ? `&sort_by=${searchParams?.get("sort_by")}`
          : ""
      }`
    );
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    handleSearchJobs(searchTerm);
  };

  return (
    <div
      ref={searchSectionRef}
      className="max-w-3xl relative search-section-container text-gray-700 z-10"
    >
      <Search
        placeholder={homeTranslation["search-job-placeholder"]}
        btnText={homeTranslation["search-jobs"]}
        value={searchTerm}
        onChange={handleInputChange}
        handleCancel={() => handleSearchJobs("")}
        handleSubmit={handleSubmit}
      />

      {showResults && searchResults?.length > 0 && !loading ? (
        <ul className="flex overflow-auto max-h-60 flex-col w-full text-left bg-white p-2 gap-1 rounded-xl border border-gray-100 shadow-lg absolute top-full mt-2">
          {searchResults.map((result) => (
            <li key={result.id}>
              <Link
                href={`/jobs?search=${result.functionalArea}`}
                onClick={() => {
                  setShowResults(false);
                  setSearchTerm(result.functionalArea);
                }}
                className="flex px-3 py-1.5 items-start gap-3 hover:bg-gray-100 transition rounded-lg"
              >
                <div className="py-1">
                  <h4 className="text-sm font-medium mb-0.5">
                    {result.functionalArea}
                  </h4>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      ) : null}
      {loading ? (
        <ul className="flex flex-col w-full text-left bg-white p-2 gap-1 rounded-xl border border-gray-100 shadow-lg absolute top-full mt-2">
          {loadingResultsCount.map((_: any, index: number) => (
            <li
              key={index}
              className="flex px-3 h-[42px] items-center gap-3 hover:bg-gray-100 transition rounded-lg animate-pulse"
            >
              {/* <div>
                  <div className="w-12 h-12 rounded-lg bg-slate-200"></div>
                </div> */}
              <div>
                <div className="w-44 h-3.5 rounded-full bg-slate-200"></div>
              </div>
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  );
};

export default homeJobSearch;
