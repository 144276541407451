"use client";
import React from "react";
import { IoSearchOutline } from "react-icons/io5";
import { XMarkIcon } from "@heroicons/react/24/outline";

import { Button } from "./button";
import { cn } from "@lib/utils";
import { useCookies } from "next-client-cookies";
import { classNames } from "@lib/helpers";
import { useIntl } from "react-intl";

interface SearchProps {
  placeholder?: string;
  btnText?: string;
  handleSubmit: React.FormEventHandler<HTMLFormElement> | undefined;
  onChange: React.ChangeEventHandler<HTMLInputElement> | undefined;
  handleCancel: () => void;
  value: string;
  className?: string;
}
const Search = ({
  placeholder,
  btnText,
  value,
  onChange,
  handleCancel,
  handleSubmit,
  className,
}: SearchProps) => {
  const intl = useIntl();
  const commonTranslation: any = intl.messages?.common;
  const cookies = useCookies();
  const isArabic = cookies.get("lang") === "ar";

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="flex items-center relative z-10">
          <div
            className={classNames(
              "text-xl lg:text-2xl absolute pointer-events-none text-gray-400",
              isArabic ? "right-4 lg:right-5" : "left-4 lg:left-5"
            )}
          >
            <IoSearchOutline />
          </div>

          <input
            type="text"
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            className={cn(
              "w-full h-[43px] sm:h-16 text-sm lg:text-base rounded-[4px] focus:ring-primary text-gray-700 focus:outline-none shadow-md border-gray-200 border border-[#B9BDC2]",
              isArabic ? "pr-12 lg:pr-14" : "pl-12 lg:pl-14",
              isArabic && value ? "pl-10" : "",
              !isArabic && value ? "pr-10" : "",
              className
            )}
          />

          <div
            className={classNames(
              "absolute flex items-center gap-2",
              isArabic ? "left-2 top-2 bottom-2 " : "right-2 top-2 bottom-2"
            )}
          >
            {value ? (
              <button
                type="button"
                onClick={handleCancel}
                className="inline-block p-1"
              >
                <XMarkIcon className="w-6" />
              </button>
            ) : null}

            <Button className="!bg-[#F2F2F2] text-[14px] h-[27px] text-[#000] font-medium rounded-[4px]  px-5 h-full sm:inline-flex hidden">
              {btnText || commonTranslation?.["search-jobs"]}
            </Button>
          </div>
        </div>
        
        <Button className="px-5 w-full hidden mt-3">
          {btnText || commonTranslation?.["search-jobs"]}
        </Button>
      </form>
    </div>
  );
};

export default Search;
